import React, { useEffect } from "react";

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import { WrapPageElementBrowserArgs } from "gatsby";
import App from "./src/App";

const applySmoothScroll = () => {
    const smoothScrollHandler = function(this: Document, e: MouseEvent) {
        const target = e.target as Element | EventTarget | null;
        const linkElement = target && "closest" in target &&  target.closest<HTMLAnchorElement>("a[href^='#']");
        
        if (linkElement) {
            e.preventDefault();
            const attributes = linkElement.attributes as { href?: { value?: string } };
            const targetId = attributes.href?.value;

            if (targetId) {
                const target = this.querySelector(targetId);
                target?.scrollIntoView({ behavior: "smooth", });
            }
        }
    };

    document.addEventListener("click", smoothScrollHandler);

    return smoothScrollHandler;
};

const applyCustomCursor = (body: HTMLBodyElement | null, pointer: HTMLDivElement | null) => {
    const mouseMoveHandler = function(e: MouseEvent) {
        if (pointer) {
            pointer.style.left = e.clientX + "px", 
            pointer.style.top = e.clientY + "px";
        }
    };

    body?.addEventListener("mousemove", mouseMoveHandler);

    return mouseMoveHandler;
};

// @todo add for individual components instead
const applyClickableItemsHoverEffect = (pointer: HTMLDivElement | null) => {
    const clickableItems = document.querySelectorAll("a, button");

    clickableItems.forEach((item) => {
        item.addEventListener("mouseenter", () => {
            pointer?.classList.add("large");
        });
        item.addEventListener("mouseleave", () => {
            pointer?.classList.remove("large");
        });
    });
};

const setViewHeight = () => {
    const root = document.documentElement;
    root.style.setProperty("--view-height", window.innerHeight + "px");
};

const Wrapper = (props: WrapPageElementBrowserArgs) => {

    useEffect(() => {
        const pointer = document.querySelector<HTMLDivElement>("#pointer");
        const body = document.querySelector<HTMLBodyElement>("body");

        const smoothScroll = applySmoothScroll();
        const mouseMouseHandler = applyCustomCursor(body, pointer);

        const handleLoad = () => {
            if (document.readyState === "complete") {
                body?.classList.add("did-load");
            }
        };

        // Handle load
        setTimeout(() => {
            handleLoad();
        }, 500);
  
        window.addEventListener("load", handleLoad);
        document.addEventListener("DOMContentLoaded", handleLoad);

        // Set view height on initial load and add listener
        setViewHeight();
        window.addEventListener("resize", setViewHeight);

        applyClickableItemsHoverEffect(pointer);
    
        return () => {
            window.removeEventListener("load", handleLoad);
            window.removeEventListener("resize", setViewHeight);
            document.removeEventListener("click", smoothScroll);
            body?.removeEventListener("mousemove", mouseMouseHandler);
        };
    }, []);


    return (
        <App {...props} />
    );
};

export const wrapPageElement = (props: WrapPageElementBrowserArgs) => <Wrapper {...props} />;
