import React from "react";
import { Helmet } from "react-helmet";
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";
import { motion } from "framer-motion";
import { createTheme, ThemeProvider } from "@mui/material";
import ScrollButton from "./components/scroll-button/ScrollButton";

import "./legacy-css.scss";
import "./app.scss";


const theme = createTheme({
    palette: {
        mode: "dark",
        error: {
            main: "#ff7a78;",
        },
    },
});

type Props = (WrapPageElementBrowserArgs | WrapPageElementNodeArgs);

const App = (props: Props) => {
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <title>StaffPanda</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=100, initial-scale=1" />
                <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700%7COswald:300,400,500,600,700" rel="stylesheet" type="text/css" />
            </Helmet>

            <div className="loading-overlay" />
        
            <div className="loading">
                <img className="logo-loading" src="/assets/images/logo-loading.svg" alt="logo" />
            </div>
          
            <div className="pointer" id="pointer" />

            <ScrollButton />
            
            <header className="fixed-header">
                <div className="header-flex-box">
                    <div className="logo">
                        <div className="logo-img-box">
                            <motion.img
                                className="logo=white"
                                src="/assets/images/logo.svg"
                                alt="logo"
                                initial={{ y: "110%", }}
                                animate={{ y: "0%", }}
                                transition={{
                                    delay: 0.25,
                                    duration: 1,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </header>

            <main>
                {props.element}
            </main>
        
            <footer className="footer bg-dark-1">
                <p>&copy; Copyright {new Date().getFullYear()} StaffPanda | All Rights Reserved</p>
            </footer>
        </ThemeProvider>
    );
};

export default App;
