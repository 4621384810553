import React from "react";

import "./scroll-button.scss";

const ScrollButton = () => {
    return (
        <a className="to-top-btn pointer-small" href="#up">
            <span className="to-top-arrow"></span>		    
        </a>
            
    );
};

export default ScrollButton;
